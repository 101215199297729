<template>
  <v-container fluid>
    <h1>TESTE</h1>
  </v-container>
</template>

<script>
  export default {
    name: 'Home',
    components: {},
  }
</script>
