<template>
  <v-app>
    <v-main>
      <router-view/>
      <v-overlay color="#a11217" opacity="1" :value="showOverlay">
        <img class="logo" src="@/assets/ipoglogowhite.png" alt="logo_branco">
        <h1 class="font-weight-light welcome-text">BEM VINDO(A) AO IPOG PROOFS</h1>
        <v-progress-linear indeterminate color="white" class="mt-3" />
      </v-overlay>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  data: () => ({
    showOverlay: true
  }),
  created() {
    this.$root.$on(`OVERLAY::OFF`, () => {
      this.showOverlay = false
    })
  }
};
</script>

<style scoped>
  .logo {
    width: 400px;
  }
  .welcome-text {
    font-size: 27.6px;
  }
</style>
