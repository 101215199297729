
<template>
  <v-container class="ma-0 pa-0" fluid>
    <v-app-bar elevation="4" color="#a11217" absolute>
      <v-toolbar-title>
        <img class="logo-bar mt-2" src="@/assets/ipoglogowhite.png" alt="ipog_logo">
      </v-toolbar-title>
    </v-app-bar>
    <v-row justify="center" align="center" class="main-box">
      <v-col v-if="stage == 1" cols="12" sm="12" md="6">
        <v-card>
          <v-card-title>PREPARADO(A) PARA INICIARMOS?</v-card-title>
          <v-divider />
          <div class="px-4 py-4">
            <p>PROVA <strong>{{ proof.proo_name }}</strong>.</p>
            <p>PROFESSOR <strong>{{ proof.proo_teacher }}</strong>.</p>
            <p>Antes de iniciar a prova, precisaremos validar algumas informações suas, será bem rapido!</p>
            <v-btn color="#a11217" class="white--text" @click="stage = 2">Começar!</v-btn>
          </div>
        </v-card>
      </v-col>
      <v-col v-if="stage == 2" cols="12" sm="12" md="6">
        <v-card>
          <v-card-title>DIGITE SEU CPF ABAIXO</v-card-title>
          <v-divider />
          <div class="px-4 py-4">
            <v-form v-model="documentForm.valid" ref="documentForm" lazy-validation>
              <v-text-field v-model="student.document" placeholder="SEU CPF AQUI" v-mask="'###.###.###-##'" :rules="rules.document" @keypress.enter.prevent="verifyDocument()" outlined/>
            </v-form>
            <p>Vamos validar se você já tem um cadastro ativo em nosso sistema</p>
            <v-btn color="#a11217" class="white--text" @click="verifyDocument()">Verificar agora</v-btn>
          </div>
        </v-card>
      </v-col>
      <v-col v-if="stage == 3" cols="12" sm="12" md="6">
        <v-card class="card-registry">
          <v-card-title>ESSA É SUA PRIMEIRA VEZ POR AQUI!</v-card-title>
          <v-card-subtitle>VAMOS FAZER UM PEQUENO CADASTRO PARA CONTINUAR</v-card-subtitle>
          <v-divider />
          <div class="px-4 py-4">
            <v-form v-model="studentForm.valid" ref="studentForm" lazy-validation>
              <v-row class="pt-4">
                <v-col cols="12" sm="12" class="py-0">
                  <v-text-field v-model="student.name" label="NOME COMPLETO" :rules="rules.name" outlined/>
                </v-col>
                <v-col cols="12" sm="12" class="py-0">
                  <v-text-field v-model="student.mail" label="E-MAIL DE CONTATO" :rules="rules.mail" outlined/>
                </v-col>
                <v-col cols="12" sm="12" class="py-0">
                  <v-text-field v-model="student.telephone" label="TELEFONE DE CONTATO" :rules="rules.telephone" outlined/>
                </v-col>
                <v-col cols="12" sm="12" class="py-0">
                  <v-text-field v-model="student.document" label="DOCUMENTO (CPF)" disabled outlined/>
                </v-col>
                <v-col cols="12" sm="12" class="py-0">
                  <v-text-field v-model="student.address_zipcode" label="CÓDIGO POSTAL (CEP)" v-mask="'#####-###'" :rules="rules.zipCode" @keyup="getPostalCode()" outlined/>
                </v-col>
                <v-col cols="12" sm="12" class="py-0">
                  <v-text-field v-model="student.address" label="LOGRADOURO" :rules="rules.addressName" disabled outlined/>
                </v-col>
                <v-col cols="12" sm="12" md="6" class="py-0">
                  <v-text-field v-model="student.address_number" label="NUMERO" :rules="rules.addressName" outlined/>
                </v-col>
                <v-col cols="12" sm="12" md="6" class="py-0">
                  <v-text-field v-model="student.address_complement" label="COMPLEMENTO" outlined/>
                </v-col>
                <v-col cols="12" sm="12" md="4" class="py-0">
                  <v-text-field v-model="student.address_district" label="BAIRRO" :rules="rules.addressDistrict" disabled outlined/>
                </v-col>
                <v-col cols="12" sm="12" md="4" class="py-0">
                  <v-text-field v-model="student.address_city" label="CIDADE" :rules="rules.addressCity" disabled outlined/>
                </v-col>
                <v-col cols="12" sm="12" md="4" class="py-0">
                  <v-text-field v-model="student.address_state" label="ESTADO" :rules="rules.addressState" disabled outlined/>
                </v-col>
              </v-row>
            </v-form>
            <!-- <p>Vamos validar se você já tem um cadastro ativo em nosso sistema</p> -->
            <v-btn color="#a11217" class="white--text" @click="createStudent()">Cadastrar</v-btn>
          </div>
        </v-card>
      </v-col>
      <v-col v-if="stage == 4" cols="12" sm="12" md="6">
        <v-card>
          <v-card-title>PERFEITO, LOCALIZAMOS AQUI SEUS DADOS</v-card-title>
          <v-divider />
          <div class="px-4 py-4">
            <p>NOME <strong>{{ student.stud_name }}</strong></p>
            <p>E-MAIL <strong>{{ student.stud_mail }}</strong></p>
            <p>TELEFONE <strong>{{ student.stud_telephone }}</strong></p>
            <p>Tudo pronto! A partir de agora, você pode iniciar a prova. O sistema irá calcular o tempo de cada resposta, você terá apenas uma chance e não poderá fechar a janela de prova até concluir todas as respostas.</p>
            <v-btn color="#a11217" class="white--text" @click="verifyProof()">Iniciar a prova</v-btn>
          </div>
        </v-card>
      </v-col>
      <v-col v-if="stage == 5" cols="12" sm="12" md="8">
        <v-stepper v-model="step" class="stepper" :key="!!questions.length">
          <v-stepper-header>
            <template v-for="(question, index) in questions">
              <v-stepper-step :step="index+1" :complete="step > index+1" :key="index" />
              <v-divider v-if="(index+1) < questions.length" :key="index+1000" />
            </template>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content v-for="(question, index) in questions" :key="index" :step="index+1">
              <div v-html="question.ques_text"></div>
              <v-radio-group v-model="questions[index].answer" class="ml-4">
                <v-radio v-for="(answer, index) in question.answers" :key="index" :value="answer.answ_uuid" :label="answer.answ_value" />
              </v-radio-group>
              <v-divider class="mb-6" />
              <v-btn color="#a11217" class="white--text mb-2" :disabled="!nextButton" :loading="!nextButton" @click="nextQuestion(index)">Confirmar resposta</v-btn>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
      <v-col v-if="stage == 6" cols="12" sm="12" md="6">
        <v-card>
          <v-card-title>PERFEITO, CADASTRADO(A) COM SUCESSO!</v-card-title>
          <v-divider />
          <div class="px-4 py-4">
            <p>NOME <strong>{{ student.stud_name }}</strong></p>
            <p>E-MAIL <strong>{{ student.stud_mail }}</strong></p>
            <p>TELEFONE <strong>{{ student.stud_telephone }}</strong></p>
            <p>Tudo pronto! A partir de agora, você pode iniciar a prova. O sistema irá calcular o tempo de cada resposta, você terá apenas uma chance e não poderá fechar a janela de prova até concluir todas as respostas.</p>
            <v-btn color="#a11217" class="white--text" @click="verifyProof()">Iniciar a prova</v-btn>
          </div>
        </v-card>
      </v-col>
      <v-col v-if="stage == 7" cols="12" sm="12" md="6">
        <v-card class="pa-8">
          <h2 class="text-center font-weight-medium title">VOCÊ CONCLUIU A AVALIAÇÃO ATÉ O FINAL!</h2>
          <h3 class="text-center font-weight-light subtitle-1">EM BREVE O SEU CONSULTOR IPOG IRÁ ENTRAR EM CONTATO</h3>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="proofDialog" max-width="500" persistent>
      <v-card class="pa-8">
        <h2 class="text-center font-weight-medium title">OPS, VOCÊ JÁ FEZ ESSA PROVA!</h2>
        <h3 class="text-center font-weight-light subtitle-1">EM CASO DE DÚVIDAS, PROCURE O SEU CONSULTOR IPOG</h3>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { cpf } from 'cpf-cnpj-validator'
import cep from 'cep-promise'
import moment from 'moment'

export default {
  name: 'Proof',
  props: [ 'uuid' ],
  data() {
    return {
      proof: {},
      stage: 1,
      step: 1,
      student: {
        document: '',
        name: '',
        mail: '',
        telephone: '',
        address_zipcode: '',
        address: '',
        address_number: '',
        address_complement: '',
        address_district: '',
        address_city: '',
        address_state: ''
      },
      nextButton: true,
      documentForm: {
        valid: true
      },
      studentForm: {
        valid: true
      },
      rules: {
        document: [
          v => !!v || 'CPF obrigatório.',
          v => v.length == 14 || 'CPF incompleto.',
          v => cpf.isValid(v) || 'CPF invalido.',
        ],
        name: [
          value => !!value || `Nome obrigatório`,
          value => value.length >= 10 || `Nome precisa ter pelo menor 10 caracteres`,
          value => value.length <= 40 || `Nome pode ter até 40 caracteres`
        ],
        mail: [
          value => !!value || `E-mail obrigatório`,
          value => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value) || `Insira um e-mail valido`
        ],
        telephone: [
          value => !!value || `Telefone obrigatório`,
          value => value.length >= 10 || `Telefone precisa ter pelo menor 10 caracteres`,
          value => value.length <= 40 || `Telefone pode ter até 40 caracteres`
        ],
        zipCode: [
          value => !!value || `CEP obrigatório`,
          value => value.length == 9 || `CEP incompleto`,
        ],
        addressName: [
          value => !!value || `Logradouro obrigatório`,
        ],
        addressNumber: [
          value => !!value || `Número obrigatório`,
        ],
        addressDistrict: [
          value => !!value || `Bairro obrigatório`,
        ],
        addressCity: [
          value => !!value || `Cidade obrigatório`,
        ],
        addressState: [
          value => !!value || `Estado obrigatório`,
        ],
      },
      questions: [],
      proofDialog: false,
      studentProof: undefined,
    }
  },
  created() {

    this.$axios.get(`/proofs/${this.uuid}`).then(success => {
      this.proof = success.data.proof
      setTimeout(() => {
        this.$root.$emit(`OVERLAY::OFF`)
      }, 4000)
    }).catch(err => {
      console.log(`Erro ao obter prova`)
    })
  },
  methods: {
    async verifyDocument() {
      if (this.$refs.documentForm.validate()) {
        let document = (this.student.document.replaceAll(`-`, ``)).replaceAll(`.`,``)
        this.$axios.get(`/students/document/${document}`).then(success => {
          this.student = success.data.student
          this.stage = 4
        }).catch(err => {
          this.stage = 3
        })
      }
    },
    async verifyProof() {
      this.$axios.get(`/proofs/${this.uuid}/verify/${this.student.stud_uuid}`).then(success => {
        this.$axios.post(`/students/${this.student.stud_uuid}/proof/${this.uuid}`).then(success => {
          this.startProof()
          this.studentProof = success.data.uuid
        })
      }).catch(err => {
        this.proofDialog = true
      })
    },
    async startProof() {
      this.stage = 5

      this.$axios.get(`/questions/proof/${this.uuid}`).then(success => {
        this.questions = success.data.questions
        this.questions.forEach(question => {
          this.$axios.get(`/answers/${question.ques_uuid}`).then(success => {
            question.answers = success.data.answers
            question.answer = undefined
            question.started = moment.utc()
            this.$forceUpdate()
          })
        })
      }).catch(err => {

      })
    },
    async nextQuestion(questionIndex) {
      if (!this.questions[questionIndex].answer) return
      let stopped, minutes, lastQuestion

      this.nextButton = false

      lastQuestion = questionIndex == (this.questions.length -1) ? true : false

      stopped = moment.utc()
      minutes = Math.round(moment.duration(stopped.diff(this.questions[questionIndex].started)).asMinutes())
      
      this.$axios.post(`/questions/answer/${this.questions[questionIndex].answer}`, {
        stpr: this.studentProof,
        started: this.questions[questionIndex].started.format(`YYYY-MM-DD HH:mm:ss`),
        stopped: stopped.format(`YYYY-MM-DD HH:mm:ss`),
        timeMinutes: minutes,
        lastQuestion,
        student: this.student.stud_uuid
      }).then(success => {
        if (this.step < (this.questions.length)) {
          this.step += 1
          this.questions[questionIndex+1].started = moment.utc()
        } else {
          this.stage = 7
        }
        this.nextButton = true
      })
    },
    async createStudent() {
      if (this.$refs.studentForm.validate()) {
        this.$axios.post(`/students`, {
          	name: this.student.name.toUpperCase(),
            document: (this.student.document.replaceAll(`.`,``)).replaceAll(`-`,``),
            telephone: this.student.telephone,
            mail: this.student.mail.toUpperCase(),
            address: this.student.address,
            address_number: this.student.address_number,
            address_complement: this.student.address_complement,
            address_district: this.student.address_district,
            address_city: this.student.address_city,
            address_state: this.student.address_state,
            address_zipcode: this.student.address_zipcode,
        }).then(success => {
          // this.stage = 6
          this.$axios.get(`/students/document/${(this.student.document.replaceAll(`.`,``)).replaceAll(`-`,``)}`).then(success => {
            this.student = success.data.student
            this.stage = 6
          })
        }).catch(err => {

        })
      }
    },
    async getPostalCode() {
      if (this.student.address_zipcode.length == 9) {
        cep(this.student.address_zipcode.replace('-', '')).then(address => {
          this.student.address = address.street
          this.student.address_district = address.neighborhood
          this.student.address_city = address.city
          this.student.address_state = address.state
        }).catch(err => {
          
        })
      }
    },
  }
}
</script>

<style scoped>
  .logo-bar {
    max-height: 50px;
  }
  .main-box {
    height: 100vh;
  }
  .card-registry, .stepper {
    margin-top: 90px;
    margin-bottom: 30px;
  }
</style>
